@import 'Shared/styles/spacing.scss';

.helpLinkContainer {
    padding: $spacing-md 0;
    display: flex;
    flex-direction: column;
    gap: $spacing-xs;

    .rateExperienceLink {
        padding-left: $spacing-xs-1;
    }
}
