@import 'Shared/styles/colors.scss';

.demoHubHostContainer {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    width: 100%;
    height: 94vh;

    .mainContentHost {
        width: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
    }

    .copilotHost {
        min-width: 40%;
        display: none;
        visibility: hidden;
        overflow: hidden;
    }

    .dragHandle {
        width: 10px;
        cursor: col-resize;
      }
}
