.dataPreviewContainer {
    margin: 12px 0;

    .tableContainer {
        overflow: auto;
    }

    .filterContainer {
        margin-bottom: 12px;
        display: flex;
        justify-items: right;
    }

    .actionContainer {
        margin: 12px 0;
        display: flex;
        justify-items: right;
    }

    table {
        width: 100%;
        border-radius: 8px;
        border: 1px solid black;
        border-collapse: collapse;
        background: #FFF;
        padding: 12px 0;

        tr {
            padding: 4px;
            border: 1px solid black;
            width: 10%;

            th {
                padding: 4px;
                border: 1px solid black;
                width: 10%;
            }

            td {
                padding: 4px;
                border: 1px solid black;
                width: 10%;
            }
        }
    }

    .headerBorder {
        border-bottom: 1px solid;
    }
}
