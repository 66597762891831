@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';
@import 'Shared/styles/spacing.scss';

.header {
    color: $white;
    font-size: $font-size-sm-1;
    font-family: $default-font-family;
    padding: 0 $spacing-sm-2;
    flex-shrink: 0;

    .menuContainer {
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        gap: $spacing-sm-2;

        a, i {
            color: $white;
            text-decoration: none;
        }
    }

    .copilotIcon {
        background: none;
        border: none;
        cursor: pointer;

        &:hover {
            background: $white !important;
            border-radius:2px;
        }

        img {
            height: 24px;
            width: 24px;
        }
    }

    button.iconContainer {
        &:focus {
            outline: solid 1px $white !important;

            &::after {
                outline: none !important;
            }
        }

        &:hover {
            i {
                color: $primary !important;
            }
        }
    }
}