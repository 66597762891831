/* Font Family */
$default-font-family: 'Segoe UI';

/* Font Weights */
$normal: 400;
$semi-bold: 600;
$bold: 700;

/* Font Sizes */
$font-size-xs: 10px;
$font-size-xs-1: 11px;
$font-size-xs-2: 12px;
$font-size-sm: 14px;
$font-size-sm-1: 16px;
$font-size-sm-2: 18px;
$font-size-sm-3: 20px;
$font-size-md: 24px;
$font-size-lg: 32px;
$font-size-lg-2: 48px;
$font-size-xl: 68px;