@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';
@import 'Shared/styles/spacing.scss';

.footer {
    color: $black-secondary;
    font-size: $font-size-xs-1;
    font-family: $default-font-family;
    line-height: 14px;
    flex-grow: 0;

    .footerWrapper {
        padding: $spacing-md;
    }

    hr {
        color: $neutral-gray-60;
        height: 1px;
    }

    .footerItems {
        display: flex;
        flex-direction: row;
        margin-top: 4px;
        flex-wrap: wrap;
        gap: $spacing-md;
        justify-content: space-between;
        align-items: center;

        a {
            color: $black-primary;

            &.link {
                color: $link-color;
            }
        }
    }

    .floatingChatIcon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
        align-items: center;
        justify-content: center;
        position: fixed;
        right: 20px;
        bottom: 20px;
        transition: background-color 0.25s ease-out, box-shadow 0.25s ease-out;
        border: none;
        cursor: pointer;
        outline: none;
        display: flex;
    }

    .floatingChatIcon:hover {
        box-shadow: 0 4px 5px -2px rgba(0,0,0,.2), 0 7px 10px 1px rgba(0,0,0,.14), 0 2px 16px 1px rgba(0,0,0,.12);
    }
}

@media only screen and (max-width:$tablet-view-port) {
    .footer {
        .footerItems {
            justify-content: space-around;
        }
    }
}

@media only screen and (max-width:$mobile-view-port) {
    .footer {
        .footerItems {
            flex-direction: column;
        }
    }
}