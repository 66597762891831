@import 'Shared/styles/spacing.scss';

.announcementsPage {
    .announcementsContainer {
        display: flex;
        flex-direction: column;
        gap: $spacing-md;
    }   

    img {
        width: 100%;
        height: 100%;
    }
}
