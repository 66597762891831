@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';
@import 'Shared/styles/spacing.scss';

.heroBanner {
    padding-top: $spacing-sm;
    position: relative;

    .largeDesktopImage {
        width: 100%;
        height: 196px;
        display: block;
    }

    .desktopImage, .tabletImage, .smalltabletImage, .mobileImage, .smallMobileImage {
        display: none;
    }

    .heroCopy {
        position: absolute;
        top: $spacing-sm;
        padding: $spacing-md;
        width: calc(100% - $spacing-xl);
        color: #115EA3;
        text-align: left;

        h1 {
            font-size: $font-size-lg;
            font-weight: 600;
            line-height: 32px;
        }

        .heroSubText {
            display: flex;
            flex-direction: column;
        }

        .demoCreationButton {
            text-align: center;
        }
    }

    @media screen and (max-width: $desktop-view-port){
        .desktopImage {
            width: 100%;
            display: block;
        }
        
        .largeDesktopImage, .tabletImage, .smalltabletImage, .mobileImage, .smallMobileImage {
            display: none;
        }
    }

    @media only screen and (max-width:$tablet-view-port) {
        .tabletImage {
            width: 100%;
            display: block;
        }

        .largeDesktopImage, .desktopImage, .smalltabletImage, .mobileImage, .smallMobileImage {
            display: none;
        }
        .heroCopy {
            top: 0;
    
            h1 {
                font-size: $font-size-md;
            }
    
            .demoCreationButton {
                margin-top: $spacing-lg;
                margin-right: $spacing-xl;
                text-align: center;
            }
        }
    }


    @media only screen and (max-width:$tablet-small-view-port) {

        .smalltabletImage {
            width: 100%;
            display: block;
        }

        .largeDesktopImage, .tabletImage, .desktopImage, .mobileImage, .smallMobileImage {
            display: none;
        }

        .heroCopy {
            text-align: left;
            padding: $spacing-sm;

            .demoCreationButton {
                margin-top: $spacing-lg;
                text-align: center;
            }
        }
    }

    @media only screen and (max-width:$mobile-view-port) {

        .mobileImage {
            width: 100%;
            display: block;
            height: 320px;
        }

        .largeDesktopImage, .tabletImage, .desktopImage, .smalltabletImage, .smallMobileImage {
            display: none;
        }

        .heroCopy {
            top: 140px;
            text-align: center;

            .demoCreationButton {
                margin-top: $spacing-md-2;
                text-align: center;
            }

            h1 {
                font-size: $font-size-md;
                font-weight: 500;
                line-height: $font-size-md;
            }
        }
    }

    @media only screen and (max-width:$mobile-small-view-port) {
        .smallMobileImage {
            display: block;
            width: 100%;
            height: 355px;
        }

        .largeDesktopImage, .tabletImage, .desktopImage, .smalltabletImage, .mobileImage {
            display: none;
        }
    }
}
