@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';
@import 'Shared/styles/spacing.scss';

.loaderWrapper {
    padding: $font-size-sm-1;
    color: $primary;
    font-size: 15px;

    &:hover {
        color: $black-primary;
    }

    .loaderItems {
        gap: $spacing-sm;
        margin: 10% 0;
    }
}

.loaderOverlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;

    .overlayInner {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .overlayContent {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .loaderBox {
        background-color: #fff;
        width: 400px;
        margin: 0 auto;
        border-radius: 8px;

        .loaderItems {
        }
    }
}