@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';
@import 'Shared/styles/spacing.scss';

.breadcrumbMenu {
    margin: 0;

    a {
        font-size: $font-size-sm;
        font-style: $normal;
        font-weight: $semi-bold;
        line-height: $spacing-sm-2;
    }
}