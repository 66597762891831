@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';
@import 'Shared/styles/spacing.scss';

.questionInputContainer {
    height: 120px;
    position: absolute;
    left: 6.5%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: $white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
}

.questionInputTextArea {
    width: 100%;
    line-height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 12px;
    margin-right: 12px;
}

.questionInputSendButtonContainer {
    position: absolute;
    right: 24px;
    bottom: 20px;
}

.questionInputSendButton {
    width: 24px;
    height: 23px;
    cursor: pointer;
}

.questionInputSendButtonDisabled {
    width: 24px;
    height: 23px;
    background: none;
    color: $arsenic;
}

.questionInputBottomBorder {
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0%;
    bottom: 0%;
    background: radial-gradient(106.04% 106.06% at 100.1% 90.19%,#0F6CBD 33.63%,#8DDDD8 100%);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.questionInputOptionsButton {
    cursor: pointer;
    width: 27px;
    height: 30px;
}
