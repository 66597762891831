@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';
@import 'Shared/styles/spacing.scss';

.paginationContainer {
    display: flex;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    justify-content: center;

    .paginationInfo {
        font-size: $font-size-xs-2;
        color: #000;
        line-height: 31px;
    }

    ul {
        display: inline-flex;
        padding: $spacing-md;

        li {
            list-style: none;
            width: 32px;
            height: 32px;
            margin: 0 2px;
            line-height: 32px;

            button {
                color: black;
                float: left;
                transition: background-color .3s;
                background: transparent;
                border: 1px solid #ddd;
                cursor: pointer;
                text-align: center;
                vertical-align: middle;
                font-size: $font-size-xs-2;
                line-height: 32px;
                font-weight: 400;
                align-items: center;
                border-radius: 4px;
                height: 100%;
                width: 100%;

                i {
                    font-size: $font-size-xs-2;
                }

                &:focus, &:focus-visible {
                    outline: solid 1px $black-primary;

                    &::after {
                        outline: none !important;
                    }
                }

                &:hover {
                    background-color: $primary;
                    color: $white;
                }

                &.active {
                    background-color: $primary;
                    color: $white;
                }
            }

            &.disabled {
                pointer-events: none;

                i {
                    color: #A19F9D;
                }

                &:hover {
                    background-color: transparent;
                }
            }

            &.dots {
                border: none;
                font-size: $font-size-sm-1;
                margin: 0;
                cursor: none;

                &:hover {
                    background-color: transparent;
                    cursor: default;
                    color: $black-primary;
                }
            }
        }
    }
}
// only tablet size devices
@media only screen and (max-width:$tablet-view-port) {
    .paginationContainer {
        ul {
            padding: $spacing-sm;

            li {
                height: 25px;
                width: 25px;

                button {
                    font-size: $font-size-xs;
                    line-height: 25px;
                }
            }
        }
    }
}