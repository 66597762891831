@import 'Shared/styles/spacing.scss';

.messageBoxContainer {
    margin: $spacing-xs 0;
    padding: $spacing-xs;

    div {
        align-items: flex-start;
    }

    span {
        font-size: 14px;
    }

    i {
        margin-top: 4px;
    }

    .actionButtonContainer {
        padding: $spacing-md 0;
    }
}
:global {
    .messagebox-type-info {
        background: rgba(240, 248, 255, 1);
        border: 1px solid rgba(209, 209, 209, 1);
    }
}
