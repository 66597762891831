@import 'Shared/styles/typography.scss';

.syncDemosDialog {
    padding: 32px 0;

    .openDialogAction {
        float: right;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
}

:global {
    .refresh-demos-video-dialog {
        .ms-Dialog-main {
            max-width: 500px;
            min-width: 450px;
        }
        .ms-TextField-fieldGroup {
            border: 1px solid #C4C4C4;

            .ms-TextField-field {
              font-size: $font-size-xs-2;
            }
        }
        .ms-MessageBar-content {
            align-items: center;
        }
        .ms-MessageBar-text span{
            font-size: $font-size-xs-1;
            color: #605E5C;
        }
        .messagebox-type-info{
            background-color: #F5F5F5;
            border:none;
        }
    }
}
