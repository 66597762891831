@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';
@import 'Shared/styles/spacing.scss';

.announcementCard {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: $spacing-sm;
    border: 1px solid #F5F5F5;
    background: $white;
    box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.11), 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.13);

    h2 {
        margin: 0;
        padding: 0;
    }

    .cardSubHeading {
        font-size: $font-size-xs;
        font-weight: $normal;
        line-height: $spacing-sm;
        margin-top: 0;
        margin-bottom: $spacing-sm;
    }

    .cardBody {
        display: flex;
        flex-direction: row;
        gap: $spacing-sm;
        margin: $spacing-xs 0;

        .cardImage {
            max-width: 300px;
            max-height:300px;
        }

        .cardDescription {
            height: 80px;
        }

        p {
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            color: #333;
            display: -webkit-box;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: -0.24px;
            line-height: $spacing-sm-3;
            max-width: -webkit-max-content;
            max-width: max-content;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: top;
            padding: 0;
            margin: 0;
        }
    }

    .actionButtons {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        font-size: $font-size-sm;
        font-style: normal;
        line-height: 20px;

        .actionLabel {
            font-size: $font-size-xs-2;
        }

        button {
            color: $link-color;
        }
    }

    .separator {
        background: $neutral-gray-60;
        color: $neutral-gray-60;
        height: 1px;
    }
    // small screen size devices
    @media only screen and (max-width:$tablet-view-port) {
        .cardBody {
            &.largeView {
                flex-direction: column;
            }
        }
    }
}
