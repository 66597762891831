.answerPanel {
    margin: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .answerCard {
        display: grid;
        gap: 12px;
        justify-content: center;
        align-items: center;

        &.user {
            grid-template-columns: 95% 1fr;

            .responseCard {
                background-color: #D2E0F4;
            }
        }

        &.agent {
            grid-template-columns: 1fr 95%;

            .answerContainer {
                display:flex;
                gap:12px;
                flex-direction:column;

                a {
                    padding: 0;
                }
            }

            .messageFooter {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
