@import 'Shared/styles/spacing.scss';

.deployDemoContainer {
    .stepCards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: $spacing-sm;
        padding: $spacing-sm 0;

        .stepItem {
            display: flex;
            flex-direction: column;
            align-self: baseline;

            .imageStyle {
                height: 100%;
                width: 100%;
            }

            .contentContainer {
                display: flex;
                flex-direction: row;

                .numberStyle {
                    color: #528EFD;
                    font-size: 64px;
                    font-weight: 300;
                    line-height: 82px;
                }

                .textStyle {
                    margin-left: 24px;
                }
            }
        }
        // small screen size devices
        @media only screen and (max-width:$tablet-view-port) {
            grid-template-columns: 1fr 1fr;
        }
        // small screen size devices
        @media only screen and (max-width:$tablet-small-view-port) {
            grid-template-columns: 1fr;
        }
    }
}
