@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';
@import 'Shared/styles/spacing.scss';

.navBar {
    margin: $spacing-md 0;
    padding: $spacing-sm-2 0;
    border-bottom: 1px solid $neutral-gray-40;

    nav {
        a {
            text-decoration: none;
            padding: $spacing-sm-2;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $black-primary;

            &:hover {
                background-color: $neutral-gray-20;
            }

            &.active {
                margin: 4px;
                border-bottom: 3px solid $black-primary;
            }
        }
        // small screen size devices
        @media only screen and (max-width:$tablet-small-view-port) {
            display: flex;
            flex-direction: column;

            a {
                padding: $spacing-xs;
            }
        }
    }
}