/* Spacing */

$spacing-xs-1: 5px;
$spacing-xs: 8px;
$spacing-sm: 12px;
$spacing-sm-2: 16px;
$spacing-sm-3: 20px;
$spacing-md: 24px;
$spacing-md-2: 28px;
$spacing-lg: 32px;
$spacing-xl: 48px;

$large-desktop-view-port: 1900px;
$desktop-view-port: 1440px;
$tablet-view-port: 1023px;
$tablet-small-view-port: 767px;
$mobile-view-port: 520px;
$mobile-small-view-port: 320px;