@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';
@import 'Shared/styles/spacing.scss';

.homePage {
    .homePageContent {
        display: grid;
        grid-template-columns: 70% 1fr;
        gap: $spacing-sm-3;

        .overviewSection {
            margin: $spacing-sm 0 $spacing-md 0;
        }

        .header {
            display: flex;
            flex-direction: row;
            align-items: center;

            .heading {
                width: 50%;
                margin : 0;
            }

            .link {
                display: flex;
                width: 50%;
                justify-content: flex-end;
                align-items: center;
                gap: 4px;
                text-decoration: none;
            }
        }

        .iconWithText {
            color: $link-color;
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            padding: 8px 0;

            button {
                font-size: $font-size-sm;
            }
        }

        span,
        p,
        li {
            font-size: $font-size-sm;
            font-weight: $normal;
            line-height: $spacing-sm-3;
        }

        a {
            font-size: $font-size-sm;
            color: $link-color;
        }

        ul {
            margin: $spacing-sm $spacing-md;
            padding: 0;

            li {
                padding: 2px;
            }
        }

        h2 {
            font-size: $font-size-md;
            font-weight: $semi-bold;
            line-height: $spacing-lg;
            margin: $spacing-sm 0;
        }

        h3 {
            font-size: $font-size-sm-3;
            font-weight: $semi-bold;
            line-height: 22px;
            margin: 0;
        }

        h4 {
            font-size: $font-size-sm;
            font-weight: $semi-bold;
            line-height: $spacing-sm-3;
        }

        .annoucementsSection {
            display: flex;
            flex-direction: column;
            gap: 16px;

            img {
                width: 64px;
                height: 64px;
            }
        }

        .additionalResourcesContainer {
            margin-top: $spacing-md;
        }

        .additionalResources {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: $spacing-md;
            padding: $spacing-sm 0;

            .resource {
                padding-left: 46px;
            }
        }

        .assetsAndfeedback {
            display: flex;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;

            .assetsAndResources {
                flex-basis: 90%;
                flex-shrink: 1;

                .assetsSection {
                    display: grid;
                    grid-template-columns: 1fr 1fr;

                    .assetsContainer {
                        padding: $spacing-xs;
                        flex-grow: 1;
                        max-width: 300px;

                        ul {
                            margin: $spacing-sm $spacing-md;
                            padding: 0;
                        }

                        li {
                            padding: 4px;
                        }
                    }
                }
            }
        }

        .feedbackSection {
            display: flex;
            flex-direction: column;
            align-self: stretch;
            flex-grow: 0;
            flex-shrink: 2;

            p {
                font-size: 14px;
                font-style: normal;
                font-weight: $normal;
                line-height: $spacing-sm-3;
                margin: 0;
            }

            button {
                height: auto;
                min-height: $spacing-lg;
            }
        }

        .faqSection {
            display: flex;
            margin: $spacing-md 0;
            padding: $spacing-md $spacing-sm-3;
            flex-direction: row;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            border-radius: 8px;
            background: #9BE4FF;

            button {
                margin: $spacing-sm 0;
            }
        }

        .copilotAssetsSection {
            display: flex;
            margin: $spacing-md 0;
            padding: $spacing-md $spacing-sm-3;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            gap: 8px;
            align-self: stretch;
            border-radius: 8px;
            background-image: url("../../../public/images/copilot-assets/copilot-asset_background.png");
            background-size: cover;

            button {
                margin: $spacing-sm 0;
            }
        }

        .newsLetterSection {
            display: flex;
            padding: 24px 16px 32px 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;
            border-radius: 8px;
            background: #EBECEC;
        }

        .solutionPlayCardsContainer {
            margin: $spacing-sm 0 $spacing-xl 0;
        }

        .solutionPlayCards {
            gap: $spacing-sm-2;
            display: flex;
            justify-content: center;

            img {
                width: 100%;
            }
        }
        // small screen size devices
        @media only screen and (max-width:$tablet-view-port) {
            grid-template-columns: 60% 1fr;

            .assetsAndfeedback {
                flex-direction: column;
            }

            .additionalResources {
                grid-template-columns: 1fr 1fr;
            }

            .solutionPlayCards {
                flex-direction: column;
            }
        }
        // small screen size devices
        @media only screen and (max-width:$tablet-small-view-port) {
            grid-template-columns: 1fr;

            .additionalResources {
                grid-template-columns: 1fr;
            }

            .solutionPlayCards {
                flex-direction: column;
            }
        }
    }
}