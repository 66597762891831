.starterPrompts {
    background: #fff;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.14);

    .helptText {
        padding: 4px 0;
        margin-bottom:8px;

        span {
            line-height: 20px;
        }
    }

    .promptCards {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }
}
