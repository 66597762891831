.dataGenInputsContainer {
    .selectedInfoCard {
        margin: 12px 0;
        padding: 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        border-radius: 8px;
        border: 1px solid #D1D1D1;
        background: #FFF;
    }

    .nlpInputContainer {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .formContainer {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .formFieldContainer {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }

        .formActionContainer {
            display: flex;
            flex-direction: row;
        }
    }
}
