@import 'Shared/styles/spacing.scss';
@import 'Shared/styles/typography.scss';

.newDemoRequestForm {

    .heading {
        h1 {
            font-size: $font-size-md;
            font-weight: $semi-bold;
            margin-bottom: $spacing-sm-2;
        }

        span {
            font-size: $font-size-sm;
        }
    }
}

.formControlsContainer {
    padding: $spacing-md 0;
    width: 50%;

    img {
        padding: $spacing-sm 0;
        width: 100%;
    }

    .templateInfoContainer {
        width: 400px;

        .productsIncludedLabel {
            padding-top: $spacing-sm;
            padding-bottom: 0;
        }

        span {
            font-size: $font-size-sm;
            font-weight: $normal;
            line-height: 20px;
        }
    }
}

.userConsentBox {
    display: flex;
    flex-direction: row;
    gap: $spacing-xs;
}


@media only screen and (max-width:$tablet-view-port) {
    .formControlsContainer {
        width: 70%;

        .templateInfoContainer {
            width: 350px;
        }
    }
}

@media only screen and (max-width:$tablet-small-view-port) {
    .formControlsContainer {
        width: 90%;

        .templateInfoContainer {
            width: 300px;

            span {
                font-size: $font-size-xs;
                font-weight: $normal;
                line-height: 16px;
            }
        }
    }
}
