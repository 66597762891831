$primary: #0c75c8;
$lighter: #d1e6f6;
$light: #abd1ee;
$tertiary: #61a7dd;
$secondary: #2484ce;
$darkAlt: #0b6ab3;
$dark: #095a97;
$darker: #07426f;
$neutralLighterAlt: #faf9f8;
$neutralLighter: #f3f2f1;
$neutralLight: #edebe9;
$neutralQuaternaryAlt: #e1dfdd;
$neutralQuaternary: #d0d0d0;
$neutralTertiaryAlt: #c8c6c4;
$neutralTertiary: #595959;
$neutralSecondary: #373737;
$neutralSecondaryAlt: #373737;
$neutralPrimaryAlt: #2f2f2f;
$neutralPrimary: #000000;
$neutralDark: #151515;
$neutral-gray-20: #efefef;
$neutral-gray-40: #ece6e6;
$neutral-gray-60: #C8C6C4;
$black-primary: #000;
$black-secondary: #171717;
$white: #fff;
$raisin-black: #242424;
$dark-silver: #707070;
$light-silver: #969696;
$arsenic: #424242;
$anti-flash-white: #f2f2f2;
$link-color: #0f6cbd;
$american-silver: #d1d1d1;
$granite-gray: #616161;
$dark-charcoal: #323130;
$greek-flag-blue: #115ea3;
$emerald: #47BF79;
$medium-purple: #9D70FF;
$sail: #A4CFF2;
$red: #C50F1F