@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';
@import 'Shared/styles/spacing.scss';

.demoRequests {
    margin-bottom: $spacing-md;

    .noDataBox {
        padding: $spacing-md;
    }

    .infoBox {
        padding-top: $spacing-sm;

        .infoMessage {
            font-size: $font-size-md;
            font-weight: $semi-bold;
            line-height: 32px;
            padding-top: 0;
        }
    }

    .requestsContainer {
        display: grid;
        gap: $spacing-sm;
        grid-template-columns: 30% 1fr;
        margin: $spacing-sm 0;
        border: 1px solid #e3e0e0;
        transition: all .1s ease-in;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0 6.4px 14.4px 0 rgba(0, 0, 0, .132), 0 1.2px 3.6px 0 rgba(0, 0, 0, .108);
        overflow-x: hidden;

        .grayedIcon {
            color: #797775;
        }

        .imageContainer {
            background-color: #dcf0ff;

            img {
                object-fit: initial;
                width: 100%;
                max-width: 100%;
            }
        }
        .removeButton{
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: $font-size-sm;
            cursor: pointer;
            padding: 4px;
        }
        .removeButton:hover{
            background-color: #f3f2f1;
            border-radius: 2px;
        }

        .copyContainer {
            display: flex;
            flex-direction: column;
            padding: $spacing-sm-2 $spacing-sm 0 $spacing-sm;

            p {
                font-size: $font-size-sm-1;
                font-weight: $semi-bold;
                line-height: 8px;
            }

            .tilteContainer{
                display: flex;
                justify-content: space-between;
                align-items: end;
            }

            .metaInfo {
                display: grid;
                grid-template-columns: auto auto auto auto;
                grid-column-gap: $spacing-md;
                grid-row-gap: $spacing-md;
                width: max-content;
                flex: 1;

                div {
                    display: flex;
                    flex-direction: column;

                    span {
                        align-items: center;
                        vertical-align: middle;
                    }
                }

                .iconContentContainer {
                    gap: $spacing-xs;
                    display: flex;
                    flex-direction: row;
                    padding-top: 8px;
                    padding-bottom: 4px;
                    align-items: center;

                    .provisioningStatusIcon {
                        padding: 0;
                    }

                    span {
                        color: $black-primary;
                        font-size: $font-size-sm;
                        font-weight: $normal;
                        line-height: $spacing-sm-3;
                    }
                }

                span {
                    font-size: $font-size-xs-2;
                    font-weight: $semi-bold;
                    color: #666666;
                    line-height: 16px;
                }
            }

            .clickthroughMetaInfo {
                display: grid;
                flex: 1;

                .description p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: $font-size-sm;
                    line-height: 20px;
                }
            }

            .envLinkContainer {
                color: #016CBE;
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;
                flex: 1;

                span {
                    i {
                        color: #016CBE;
                    }
                }
            }
        }

        .cardClickthroughDemoType {
            border-right: 6px solid $medium-purple;
        }

        .cardSharedDemoType {
            border-right: 6px solid $sail;
        }

        .cardDefaultDemoType {
            border-right: 6px solid $emerald;
        }

        .demoEnvironmentName {
            max-width: 700px;
        }

        .demoRequestLabel {
            max-width: 360px;
        }

        .environmentLink {
            display: flex;
            align-items: center;
        }

        .turncateText {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            a {
                font-size: $font-size-sm;
            }
        }

        .buttonsContainer {
            display: flex;
            flex-direction: row;
            gap: 8px;
            margin: $spacing-sm 0;
        }

        // only desktop size devices
        @media only screen and (max-width:$desktop-view-port) {
            grid-template-columns: 40% 1fr;
            gap: 12px;

            .demoRequestLabel {
                max-width: 350px;
            }

            .copyContainer {
                padding: $spacing-xs;
                gap: 0px;

                p {
                    font-size: $font-size-sm;
                    font-weight: $semi-bold;
                    line-height: 8px;
                }

                .innerText {
                    padding: 4px 0;
                }

                .metaInfo {
                    grid-template-columns: repeat(2, 1fr);
                    margin-top: 8px;

                    .iconContentContainer {
                        gap: 4px;
                    }
                }
            }
        }

        // only tablet size devices
        @media only screen and (max-width:$tablet-view-port) {
            .demoRequestLabel {
                max-width: 290px;
            }

            .copyContainer {
                .metaInfo {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 12px;
                    margin-top: $spacing-sm;
                }
            }
        }

        // only small tablet size devices
        @media only screen and (max-width:$tablet-small-view-port) {
            display: block;

            .copyContainer {
                padding: $spacing-sm;
                gap: 0px;

                p {
                    font-size: $font-size-sm-1;
                    font-weight: $semi-bold;
                    line-height: 8px;
                }

                .innerText {
                    padding: 4px 0;
                }

                .metaInfo {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 12px;

                    .iconContentContainer {
                        gap: 4px;
                    }
                }
            }
        }

        // only mobile size devices
        @media only screen and (max-width:$mobile-view-port) {
            .demoRequestLabel {
                max-width: 210px;
            }
        }

        // only small mobile size devices
        @media only screen and (max-width:$mobile-small-view-port) {
            .demoRequestLabel {
                max-width: 175px;
            }
        }
    }
}

.deleteDialogSubheading{
    font-size: $font-size-xs-2 ;
    font-weight: $normal ;
    .ms-MessageBar-content {
        align-items: center;
    }
}

.inputDeleteDemo{
    font-size: $font-size-xs-2,
}

// TODO: Move these common styles in base scss
.textMedium {
    font-size: $font-size-sm-1;
    font-weight: $semi-bold;
    color: $black-primary;
    line-height: 22px;
    margin: 0;
    padding: 0;
}

.textSmall {
    color: $black-primary;
    font-size: $font-size-sm;
    font-weight: $normal;
    line-height: $spacing-sm-3;
}

.iconColorGreen {
    color: green;
}

.iconColorRed {
    color: red;
}

.iconColorYellow {
    color: goldenrod;
}

:global{
    .delete-demos-dialog {
        .ms-Dialog-main {
            max-width: 500px;
            min-width: 450px;
        }
        .ms-TextField-fieldGroup {
            border: 1px solid #C4C4C4;

            .ms-TextField-field {
              font-size: $font-size-xs-2;
            }
        }
        .ms-MessageBar-content {
            align-items: center;
        }
        .ms-MessageBar-text span{
            font-size: $font-size-xs-1;
            color: #605E5C;
        }
        .messagebox-type-info{
            background-color: #F5F5F5;
            border:none;
        }
    }

    
}
