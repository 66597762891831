@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';
@import 'Shared/styles/spacing.scss';

.cardContainer {
    display: flex;

    .card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: $spacing-sm;
        gap: $spacing-sm;
        background: $white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        flex-basis: auto;
        flex-grow: 1;
        max-width: 350px;

        .cardHeading {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .iconImage {
                width: 5%;
            }

            label {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .cardBody {
            flex-grow: 1;
            width: 100%;

            p {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                max-width: max-content;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                font-style: normal;
                font-weight: 400;
                font-size: $font-size-xs-2;
                line-height: 16px;
                color: #000000;
                vertical-align: top;
            }

            .toggleContainer {
                display: flex;
                justify-content: space-between;
                align-items: baseline;

            }
        }

        .actionButtonsContainer {
            display: flex;
            justify-content: space-between; /* Arranges the buttons horizontally with space between them */
            align-items: center;
            width: 100%;

            button {
                margin: $spacing-xs 0; /* Adjust the spacing as needed */
                padding: $spacing-xs $spacing-xs;
            }
        }

        .buttonContainer {
            display: flex;
            align-items: flex-start;
            gap: $spacing-sm;
            padding-right: $spacing-sm;
            width: 100%;
        }
    }

    .cardDefaultDemoType {
        border-top: 6px solid $emerald;
    }

    .cardSharedDemoType {
        border-top: 6px solid $sail;
    }

    .cardClickthroughDemoType {
        border-top: 6px solid $medium-purple;
    }

    .cardInactive {
        border-top: 6px solid $red;
    }
}


/* Target the custom toggle container and MessageBox*/
:global {
    [class*="toggleContainer"] {
        .ms-Toggle {
            margin-bottom: 0px;
            &.is-checked {
                .ms-Toggle-background {
                    background-color: #4CAF50;
                }
            }
        }
    }
    [class*="messageBoxContainer"].toggleError {
        padding: 0px;
        margin: 0px;
        align-items: center;
        div{
            align-items: center;
        }
        span {
            font-size: $font-size-xs-2;
        }
    }
}

.customtoggle .ms-Toggle {
    width: 200px;

}

.ms-Toggle-label {
    font-size: 16px !important;
    color: #333 !important;
}

.ms-Toggle-background {
    background-color: #ccc !important;
    border-radius: 20px !important;
}

.ms-Toggle-background.is-checked {
    background-color: #4CAF50 !important;
}

.ms-Toggle-thumb {
    background-color: #fff !important;
    border: 2px solid #4CAF50 !important;
}

.ms-Toggle.is-checked .ms-Toggle-thumb {
    background-color: #fff !important;
}

/* Remove hover effect for the toggle background */
.ms-Toggle-background:hover {
    background-color: #4CAF50 !important;
}