@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';
@import 'Shared/styles/spacing.scss';

.answerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8.1285px;
    gap: 5.42px;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 5.419px;
}

.answerText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $dark-charcoal;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 11px;
    white-space: normal;
    word-wrap: break-word;
    max-width: 800px;
    overflow-x: auto;
}

.answerFooter {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    justify-content: space-between;
}

.answerDisclaimerContainer {
    display: flex;
    flex-direction: column;
}

.answerDisclaimer {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    text-align: left;
    color: $light-silver;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.citationContainer {
    margin-left: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $greek-flag-blue;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 6px;
    gap: 4px;
    border: 1px solid #D1D1D1;
    border-radius: 4px;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.citation {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 14px;
    height: 14px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    flex: none;
    flex-grow: 0;
    z-index: 2;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: $arsenic;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.accordionIcon {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin-top: 4px;
    color: $granite-gray;
    font-size: 10px;

    &:hover {
        cursor: pointer;
    }
}

.accordionTitle {
    margin-right: 5px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: $granite-gray;

    &:hover {
        cursor: pointer;
    }
}

.clickableSup {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 14px;
    height: 14px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    flex: none;
    order: 2;
    flex-grow: 0;
    z-index: 2;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: $arsenic;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

sup {
    font-size: 10px;
    line-height: 10px;
}
