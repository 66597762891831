@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';
@import 'Shared/styles/spacing.scss';

.sytemPagesContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    gap: $font-size-md;
    text-align: center;
    margin-top: 10%;
    height: 100%;

    h1 {
        color: #0C75C8;
        font-size: 10rem;
        font-weight: $semi-bold;
        padding: 0;
        margin: 0;
        color: linear-gradient(to left, #0C75C8 0%, #0ED8C6 50%);
    }

    .message {
        width: 60%;
        margin-top: 48px;

        h3 {
            font-size: 1rem;
            font-weight: $normal;
        }
    }
}

@media only screen and (max-width:$tablet-view-port) {
    .sytemPagesContainer {
        h1 {
            font-size: 8rem;
            font-weight: $semi-bold;
        }
    }
}

@media only screen and (max-width:$mobile-view-port) {
    .sytemPagesContainer {
        h1 {
            font-size: 6rem;
            font-weight: $semi-bold;
        }
    }
}
