@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';
@import 'Shared/styles/spacing.scss';

.templates {
    img {
        width: 100%;
    }

    .categoryTabs {
        div[role=tablist] {
            text-align: center;
            border-bottom: 1px solid $neutral-gray-40;

            button.is-selected {
                &::before {
                    border-radius: 999px;
                }
            }
        }
    }

    .templateCardsContainer {
        margin: $spacing-sm 0;
    }

    .templateCards {
        gap: $spacing-sm-2;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }
    
    // only mobile size devices
    @media only screen and (max-width:$tablet-small-view-port) {
        .templateCards {
            flex-direction: column;
        }
    }
}