@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';
@import 'Shared/styles/spacing.scss';

.copilotAssets {
    width: 100%; // Spread the container across the page
    img {
        width: 100%;
    }

    display: flex;


    .copilotAssetsCardsContainer {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 14px; // Adjust the gap between cards horizontally

        .card {
            display: flex;
            flex-flow: row wrap;
            padding: 8px;
            gap: 4px;
            background: $white;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
            border-radius: 4px;
            max-width: 416px;


            .cardHeading {
                width: 100%;

                label {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 5px;
                    padding-bottom: 0;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;

                }
            }

            .cardBody {
                min-height: 50px;
                flex-grow: 1;
                height: 400px;

                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    max-width: max-content;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    font-style: normal;
                    font-weight: 400;
                    font-size: $font-size-xs-2;
                    line-height: 16px;
                    color: #000000;
                    vertical-align: top;
                }

                .ownersContainer{
                    display: flex;
                    align-items: center;
                    font-weight: $bold;
                    font-size: $font-size-xs;
                    color: #1875CC;
                    gap: 7px;

                    span{
                        font-size: $font-size-xs;
                        font-weight: $normal;
                        color: $granite-gray;
                        text-wrap: wrap;
                        border-radius: 20px;
                        border: 1px solid #E0E0E0;
                        padding: 1px 4px;
                    }
                }

                .smallText {
                    font-size: $font-size-xs;
                    font-weight: $normal;
                    color: #616161;
                    text-wrap: wrap;

                    span{
                        margin-left: 2px;
                    }
                }

                .badge {
                    font-size: $font-size-xs;
                    font-weight: $normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    background-color: $white;
                }

                iframe {
                    width: 100%;
                }

                img {
                    width:100%;
                    height:226px;
                    object-fit: fill;
                }
            }

            .actionButtonsContainer {
                display: flex;
                justify-content: space-between; /* Arranges the buttons horizontally with space between them */
                align-items: center;
                width: 100%;

                button {
                    margin: $spacing-xs 0; /* Adjust the spacing as needed */
                    padding: $spacing-xs $spacing-xs;
                }
            }

            .buttonContainer {
                display: flex;
                width: 100%;
                justify-content: space-between;
                flex-grow: 0;
            }

            .upvoteButton{
                font-size: $font-size-xs-2;
                font-weight: $normal;
            }

            .upvoteButton.upvoted {
                border-color: $emerald;
            }

            .upvoteButtonSpan {
                margin-left: $spacing-xs-1;
                font-size: $font-size-xs;
                color: #8F8F8F;
            }
            .upvoteButtonIcon {
                object-fit: none;
            }

            .copilotAssetActions {
                display: flex;
                gap: 10px;
            }

            .itemsContainer {
                display: flex;
                align-items: flex-end;
                gap: 93px;
                padding-right: $spacing-sm;
                width: 100%;
                justify-content: space-between;
                flex-grow: 0;
            }

            .textCopied {
                position: absolute;
                background-color: rgba(0, 0, 0, 0.5);
                color: #fff;
                padding: 4px;
                border-radius: 4px;
                left: 55%;
                transform: translate(-50%, -50%);
                text-wrap: nowrap;
            }
        }
    }

    .titleSearchWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end; // Align to the right side
        margin-right: 15px; // Adjust margin as needed

        input[type="text"] {
            margin-right: 8px; // Adjust margin between input and icon
        }

        .search-icon {
            cursor: pointer;
        }

        label {
            margin-left: 30px; // Adjust margin as needed
        }
    }

    .sortByContainer {
        display: flex;
    }

    .selectBox {
        margin-left: 8px;
        margin-right: -12px;
        height: 26px;
        width: 213px;
    }

    .searchTextBox {
        position: relative;

        input[type="text"] {
            width: 84%; // Default width
            height: 20px; // Default height
            padding-left: 40px; // Ensure enough space for the icon
        }

        .searchIcon {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            color: #aaa; // Customize the color as needed
            z-index: 1; // Ensure the icon is above the input text
        }


        input[type="text"]:focus {
            outline: none; // Remove default focus outline
            border-color: #0078d4; // Change border color on focus
            box-shadow: 0 0 0 2px rgba(0, 120, 212, 0.3); // Add box shadow on focus
        }
    }

    .leftPanel {
        width: 300px; // Set the width of the left panel
        padding: 5px; // Add padding as needed
        // border-top: 1px solid #ccc; // Add border to separate from main content
        flex: none;

        .filtersHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .clearAllLink {
            margin-left: auto; // Push the "Clear All" link to the right
        }

        label {
            margin-bottom: 4px; // Adjust spacing between label and input
            display: block; // Ensure label is on its own line
            font-size: $font-size-sm;
            font-weight: $semi-bold;
            line-height: $spacing-sm-3;
        }

        .customDropdown {
            display: flex;
            flex-direction: column; /* Arrange items vertically */
        }

        .scrollableDropdown {
            max-height: 160px; /* Set a maximum height for the dropdown */
            overflow-y: auto;
        }

        .checkboxItem {
            //display: flex;
            //align-items: center; /* Align items vertically in each checkbox item */
            //margin-bottom: 4px; /* Adjust margin between checkbox items */
            display: flex;
            padding: 8px 12px;
            flex-direction: row;
            align-items: flex-start;
            align-self: stretch;

            label {
                margin-bottom: 4px; // Adjust spacing between label and input
                display: block; // Ensure label is on its own line
                font-size: $font-size-sm;
                font-weight: $normal;
                line-height: $spacing-sm-3;
            }
        }

        .checkboxItem input[type="checkbox"] {
            margin-right: 4px; /* Adjust margin between checkbox and label */
        }

        .inputDropdownContainer {
            // display: flex;
            margin-bottom: 10px;
        }

        .inputDropdownContainer input {
            //margin-bottom: 10px; /* Adjust the margin to create space between the input and dropdown */
        }
    }


    .mainContent {
        flex-grow: 1; // Allow the main content to grow to fill the remaining space
        padding: 20px; // Add padding as needed
        margin-top: 8px;
    }
}

:global {
    .copilot-video-dialog {
        .ms-Dialog-main {
            max-width: 730px;
            min-width: 450px;
            width:700px;
        }
    }
}

.separator {
    background: $neutral-gray-60;
    color: $neutral-gray-60;
    height: 1px;
    margin-bottom: $spacing-sm;
}

h4 {
    font-size: $font-size-sm;
    font-weight: $semi-bold;
    line-height: $spacing-sm-2;
    margin: $spacing-sm 0;
}

.videoDialogContent {
    width: 926px;
    height: 542px;
}

video {
    max-width: 100%;
    max-height: 100%;
}

// only mobile size devices
@media only screen and (max-width:$tablet-small-view-port) {
    .copilotAssetsCards {
        flex-direction: column;
    }
}
