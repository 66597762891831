@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';
@import 'Shared/styles/spacing.scss';

body {
    margin: 0;
    padding: 0;
    background: linear-gradient(180deg, $white 20%, $white 65%, #EAFFFF 100%), $white;
    background-attachment: fixed;
    font-family: $default-font-family;
    height: 100vh;
}

div#root {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow:hidden;
}

#mainContent {
    max-width: 1900px;
    margin: 0 auto;
    width: 80%;
    padding: $spacing-md;
    flex-grow:1;
}

/* External content styling update */
.sp-content {
    a {
        color: $link-color;
    }

    p, span, li {
        font-size: $font-size-sm;
        font-weight: $normal;
        line-height: $spacing-sm-3;
    }

    li {
        padding: 4px;
    }
}

@media only screen and (max-width:$mobile-view-port) {
    #mainContent {
        width: 90%;
    }

    .ms-Dialog-main {
        max-width: 350px;
        min-width: 250px;
    }
}