.demoHubCopilot {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    background: rgba(240, 248, 255, 1);

    .header {
        display: flex;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        background: #fff;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.14);
        gap: 12px;

        i {
            cursor: pointer;
        }
    }

    .body {
        padding: 12px;
        flex-grow: 1;
        overflow: auto;

        .welcomeMessage {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 70%;
            margin: 0 auto 24px auto;
            gap: 4px;
        }
    }

    .footer {
        padding: 12px;
        flex-grow: 0;
        /* FluentAI Question Textbox has max width 720. The below style is to override it*/
        div {
            max-width: none;
        }
    }

    .iconWithText {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;

        .img1 {
            height: 24px;
            width: 24px;
        }

        .img2 {
            height: 12px;
            width: 12px;
        }


        h2, h6 {
            margin: 0;
            padding: 0;
        }
    }

    .aiDisclaimer {
        margin-top: 24px;
        text-align: center;
    }
}
