@import 'Shared/styles/spacing.scss';
@import 'Shared/styles/colors.scss';
@import 'Shared/styles/typography.scss';

.templateDetailPage {
    .templateDetailContainer {
        display: flex;
        flex-direction: column;
        gap: $spacing-md;
    }

    img {
        width: 35%;
        height: 35%;
        margin: $spacing-lg 0;
        transition: width 0.5s, height 0.5s; /* Add a transition for smoother resizing */

        &.enlargedImage {
            width: 80%; /* Adjust the size as needed for the enlarged image */
            height: 80%;
        }
    }

    .topRightButton {
        position: absolute;
        top: 10px; 
        right: 10px; 
    }

    h1 {
        font-size: $font-size-md;
        font-weight: $semi-bold;
        line-height: $spacing-lg;
        margin: $spacing-sm 0;
    }

    h2 {
        font-size: $font-size-sm-3;
        font-weight: $semi-bold;
        line-height: $spacing-md-2;
        margin-top: $spacing-lg;
        margin-bottom: $spacing-sm;
    }

    h3 {
        font-size: $font-size-sm-2;
        font-weight: $semi-bold;
        line-height: $spacing-md;
        margin: $spacing-sm 0;
    }

    h4 {
        font-size: $font-size-sm;
        font-weight: $semi-bold;
        line-height: $spacing-sm-2;
        margin: $spacing-sm 0;
    }

    iframe {
        margin-top: $spacing-lg;
    }
}

.fieldList {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 250px; 
    grid-row-gap: 30px;
}

.fieldGrid {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 250px; 
    grid-row-gap: 30px
}

.fieldRow {    
}

.fieldKey {
    font-size: $font-size-xs-2;
    font-weight: $semi-bold;
    line-height: 16px;
}

.fieldValue {
    font-family: Segoe UI;
    font-size: $font-size-sm;
    font-style: normal;
    font-weight: $normal;
    line-height: 20px;
}

.separator {
    background: $neutral-gray-60;
    color: $neutral-gray-60;
    height: 1px;
    margin: $spacing-md 0;
}

.container {
    display: flex;
    flex-direction: column;
    gap: $spacing-md;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end; /* Move buttons to the right */
    gap: 8px; /* Adjust the gap between buttons */
    align-items: center;
}

.buttonContainerBottom {
    display: flex;
    justify-content: flex-start;
    gap: 8px; 
    align-items: center;
    margin-top: $spacing-md; /* Add spacing between Pivot and bottom buttons */
}

.iconWithText {
    color: $link-color;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    button {
        font-size: $font-size-sm;
    }
}

.messageBarContainer {
    margin-top: $spacing-xs;
}

.assetsAndfeedback {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    .assetsAndResources {
        flex-basis: 90%;
        flex-shrink: 1;

        .assetsSection {
            display: grid;

            .assetsContainer {
                display: flex;
                align-items: center; // Align items vertically in the container
                padding: $spacing-xs;
                flex-grow: 1;
                max-width: 600px;

                .icon {
                    width: 16px;
                    height: 16px;
                    margin-right: $spacing-xs; // Add margin to separate the icon from the link
                }

                .assetLink {
                    font-size: $font-size-xs-2;
                    font-weight: $normal;
                    line-height: $spacing-sm-2;
                    text-decoration-line: underline;
                }
            }
        }
    }
}

// Add a media query to change the layout for smaller screens
@media only screen and (max-width: 415px) {
    .header {
        flex-direction: column; /* Change to column layout for smaller screens */
        align-items: flex-start; /* Align items to the start (left) */
    }

    .buttonContainer {
        flex-direction: row; /* Change to row layout for smaller screens */
        justify-content: center;
        align-items: center; /* Center buttons for smaller screens */
        margin-top: $spacing-md; /* spacing between heading and buttons */
    }
}